import React from 'react';
// icon
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';

// services data
const services = [
  {
    name: "Entender lo que necesitas 🗒️",
    description: "Comenzamos hablando contigo para saber exactamente qué es lo que necesitas y qué quieres lograr con el software."
  },
  {
    name: "Diseñar cómo será 📏",
    description: "Creamos un plan que explica cómo será el software, cómo funcionará y qué hará por ti."
  },
  {
    name: "Construirlo 🔨",
    description: "Nuestro equipo de personas especializadas en hacer software empieza a crearlo, asegurándose de que funcione bien."
  },
  {
    name: "Probarlo mucho 👷",
    description: "Verificamos que el software funcione como debe, como cuando revisas varias veces un pastel en el horno para asegurarte de que esté perfecto."
  },
  {
    name: "Ponerlo en acción 🛸",
    description: "Lo instalamos para que puedas empezar a usarlo, explicándote cómo funciona para que te sientas cómodo."
  },
  {
    name: "Siempre estamos contigo 🔭",
    description: "Estamos aquí para ayudarte y mejorar el software según tus necesidades, incluso después de entregártelo."
  },
];

const Services = () => {
  return (
    <section className='section' id='services'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* text & image */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'
          >
            <h2 className='h2 text-sans mb-6'>Lo que hacemos ⚒️</h2>
            <h3 className='h3 max-w-[455px] mb-16 italic'>
              Nos especializamos en soluciones personalizadas para los desafíos empresariales cotidianos,
              mejorando la calidad y eficiencia de procesos para un crecimiento
              <span className="m-2 before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-kodbox-yellow-500 relative inline-block">
                <span className="relative text-white">independiente</span>
              </span>
              de software genérico (☠️).
              Nuestros enfoques evolucionan con la expansión de cada empresa. 🚀
            </h3>
          </motion.div>
          {/* services */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'
          >
            {/* service list */}
            <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:gap-8">
              {services.map((service, index) => {
                // destructure service
                const { name, description } = service;
                return (

                  <div key={name} className="gap-x-2 rounded-xl bg-white/5 p-5 ring-1 ring-inset ring-white/10">
                    <div className="text-base leading-7">
                      <h3 className="font-semibold text-white">{name}</h3>
                      <p className="mt-2 text-gray-300">{description}</p>
                    </div>
                  </div>
                  // <div
                  //   className='border-b border-white/20 h-[146px] mb-[38px] flex '
                  //   key={index}
                  // >
                  //   <div className='max-w-[476px]'>
                  //     <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-6'>
                  //       {name}
                  //     </h4>
                  //     <p className='font-secondary leading-tight'>
                  //       {description}
                  //     </p>
                  //   </div>
                  // </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
