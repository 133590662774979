import React from 'react';
// countup
// intersection observer hook
import { useInView } from 'react-intersection-observer';
// motion
import { motion } from 'framer-motion';
// variant
import { fadeIn } from '../variants';
import Image from '../assets/team.jpg';

const About = () => {
  const [ref] = useInView({
    threshold: 0.5,
  });
  return (
    <section className='section' id='about' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row items-center justify-center gap-y-10 lg:gap-x-20 h-screen'>
          {/* img */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 h-[500px]'
          >
            <div className="rounded-md relative max-w-xl mx-auto mt-20 shadow-lg shadow-kodbox-yellow-500/40">
              <img src={Image} alt="team" className="grayscale object-cover object-center h-80 w-full object-cover rounded-md" />
              <div className="absolute inset-0 rounded-md"></div>
              <div className="absolute inset-0 flex items-center justify-center">
                <h2 className="text-white text-3xl font-bold">Nosotros 🧑‍💻</h2>
              </div>
            </div>
          </motion.div>

          {/* text */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 max-w-lg'
          >
            <h3 className='h3 mb-2'>
              Verdaderos guerreros en crear soluciones para tu negocio. 🥷
            </h3>
            <p className='mb-6'>
              Apasionados por el software, con la meta de modernizar negocios para potenciar su eficacia, optimizar tiempos y fomentar su crecimiento.
              Nos impulsa la pasión por ayudar a empresas a evolucionar y alcanzar su máximo potencial. 🎯
            </p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
