import React from 'react';
// images
import Logo from '../assets/kodbox_logo_rounded.png';

const Header = () => {
  return (
    <header className='py-8'>
      <div className='container mx-auto'>
        <div className='flex justify-between items-center'>
          {/* logo */}
          <a href=''>
            <img className='w-10 h-15' src={Logo} alt='' />
          </a>
          {/* button */}
          <button className='btn btn-sm'>
            <a href='#contact'>
              Trabajemos!
            </a>
          </button>
          {/* </button> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
