import React from 'react';
// components
import Banner from './components/Banner';
import Header from './components/Header';
import Nav from './components/Nav';
import About from './components/About';
import Services from './components/Services';
// import Work from './components/Work';
import Contact from './components/Contact';

const App = () => {
  return (
    <div className='bg-gradient-to-r from-neutral-800 to-neutral-900 overflow-hidden'>
      <Header />
      <Banner />
      <Nav />
      <About />
      <Services />
      {/* <Work /> */}
      <Contact />
      {/* <div className='h-[4000px]'></div> */}
      <footer>
        <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
          <div className="mt-8 md:order-1 md:mt-0">
            <p className="text-center text-xs leading-5 text-gray-500">
              &copy; 2024 KodBox, Derechos Reservados.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
